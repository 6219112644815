import { useState } from 'react'

const ACCEPT_COOKIE_KEY = 'bv_labs_accepted_policy'

const CookiePolicyBanner = () => {
  const [accept, setAccept] = useState(
    localStorage?.getItem(ACCEPT_COOKIE_KEY) === 'true'
  )
  const [render, setRender] = useState(false)

  if (accept) {
    return null
  }

  return (
    <div className="text-sm fixed bg-gray-100 h-28 lg:h-12 justify-center bottom-0 w-screen left-0 right-0 z-20 p-4 flex flex-col lg:flex-row text-gray-500">
      <div>
        <div>
          By continuing to use our site, you agree to the use of cookies as
          stated in our privacy policy.
        </div>
      </div>
      <div className="mt-2 lg:mt-0">
        <button
          className="dark:text-green-500 text-green-800 font-bold lg:ml-4"
          onClick={() => {
            localStorage.setItem(ACCEPT_COOKIE_KEY, 'true')
            setAccept(true)
            setRender(!render) // Trigger a re-render
          }}
        >
          Accept
        </button>
        <a
          className="dark:text-green-500 text-green-800 pt-3 font-bold underline underline-offset-[6px] ml-4"
          aria-label="Link to privacy policy page"
          href="/privacy"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default CookiePolicyBanner
